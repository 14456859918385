<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学校概况</el-breadcrumb-item>
      <el-breadcrumb-item>学校简介</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="view-header">
        <el-page-header @back="goBack" :content="this.$route.query.pageTitle"></el-page-header>
      </div>
      <div class="sub-view-container">
        <div class="sub-view-head head_editor">
          <el-input class="inputC" placeholder="请在这里输入学校名称（50字以内）" maxlength='50' v-model="addForm.name"></el-input>
        </div>
        <div class="info">
          <div class="view-source">
            <p>学校地址：</p>
            <el-input size="small" v-model="addForm.address" placeholder="请输入"></el-input>
          </div>
          <div class="view-source">
            <p>办学性质：</p>
            <el-input size="small" v-model="addForm.property" placeholder="请输入"></el-input>
          </div>
          <div class="view-source">
            <p>招生阶段：</p>
            <el-input size="small" v-model="addForm.section" placeholder="请输入"></el-input>
          </div>
          <div class="view-source">
            <p>设计规模：</p>
            <el-input size="small" v-model="addForm.scale" placeholder="请输入"></el-input>
          </div>
          <div class="view-source" v-if="this.$route.query.id !== undefined">
            <p>已传首页照片：</p>
            <div class="photo-list">
              <div class="photo-item" v-for="(item, index) in bannerArr" :key="index">
                <img :src="item" alt="" style="height: 100%;float: left;"/>
                <div class="edit">
                  <i class="el-icon-zoom-in" @click="showPreviewDialog(item)"></i>
                  <i class="el-icon-delete" @click="removePhoto(item, index)"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="view-source">
            <span>上传首页照片：</span>
            <div>
              <el-upload :action="uploadURL" :on-preview="handlePreview"
                         :on-remove="handleRemove"
                         list-type="picture-card" :headers="headersObj" :on-success="handleSuccess"  :before-upload="beforeAvatarUpload">
                <i class="el-icon-plus add-icon"></i>
                <span class="tip" style="top: 23px;">限制490px*328px大小</span>
                <span class="tip" style="top: 46px;">支持png、jpg、jpeg格式图片</span>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="diveditor">
<!--            <quilleditor-widget ref="quillView" :details="addForm.content" typeStr="school"></quilleditor-widget>-->
            <ueditor-wrap v-if="finish" :details="addForm.content" ref="quillView" typeStr="school"></ueditor-wrap>
        </div>
      </div>
      <div class="btneditor">
        <el-button type="primary" @click="preserveNews" :loading="btnLoading">保 存</el-button>
      </div>
      <!-------------图片预览对话框----------------->
      <el-dialog title="图片预览" :visible.sync="previewPicVisible"
                 width="700px">
<!--        <div style="width: 100%;max-height: 450px;">
          <img :src="previewPath" style="height: 100%;" >
        </div>-->
        <!--图片宽高都小于容器时垂直、水平居中
        图片宽高都大于容器时保持宽高比将width或height充满容器-->
        <div style="width:100%;height:400px;display: table-cell;vertical-align: middle;text-align: center;">
          <img :src="previewPath" alt="" style="max-width: 100%;max-height: 100%;display: block;margin: 0 auto;"/>
        </div>

      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import QuilleditorWidget from '../common/quill_editor_widget.vue';
import UeditorWrap from '../common/ueditor_wrap.vue';
export default {
  data () {
    return {
      addForm: {
        name:'',//标题
        address: '',//学校地址
        property: '',//办学性质
        section: '',//招生阶段
        scale: '',//设计规模
        content: '',//文本编辑器
      },
      urlArr: [],//新增时的路径数组
      bannerArr: [],//已存在的路径数组
      pictureload:'',//图片是否加载完全
      /* source:'',//来源
       author: '',//作者*/
      btnLoading:false,//保存按钮loading
      // 图片路径数组
      pics: [],
     // uploadURL: 'api/upload/image/upload',
        uploadURL: this.$http.defaults.baseURL + '/upload/image/upload',
      // 上传图片的请求头
      headersObj: {
        Authorization: window.localStorage.getItem('token')
      },
      // 图片预览路径
      previewPath: '',
      // 控制图片预览对话框的显示与隐藏
      previewPicVisible: false,
        finish: false
    }
  },
  head: {
    title: '帮助管理',
  },
  components:{
      QuilleditorWidget,
      UeditorWrap
  },
  created(){
      console.log('执行父组件的mounted')
    if(this.$route.query.id){//编辑
      this.title = this.$route.query.title;
      this.getContent(this.$route.query.id);
    } else {
        this.finish = true;
    }
  },
  methods:{
    beforeAvatarUpload(file) {
      let maxSize = 3 * 1024 * 1024;
      if(file.size > maxSize) {
        this.$message.error('图片不允许超过3M！');
        return false;
      } else {
        let type = file.name.substring(file.name.lastIndexOf('.') + 1);
        if (type === 'png' || type === 'jpg' || type === 'jpeg') {

        } else {
          this.$message.error('图片格式不正确！');
          return false;
        }
      }
    },
    //删除学校简介照片接口,只是已存在的banner图数组中移出这一项，还未提交到后台
    removePhoto(item, index) {
      this.bannerArr.splice(index, 1)
    },
    //列表图片预览
    showPreviewDialog(url) {
      this.previewPath = url
      this.previewPicVisible = true
    },
    // 图片预览事件
    handlePreview (file) {
      this.previewPath = file.response.result
      this.previewPicVisible = true
    },
    // 图片移除事件
    handleRemove (file) {
      let type = file.name.substring(file.name.lastIndexOf('.') + 1);
      if (type === 'png' || type === 'jpg' || type === 'jpeg') {

      } else {

      }
    },
    // 图片上传成功处理方法
    handleSuccess (response) {
      // 将对象push到添加表单的图片路径数组中
      let url = response.result
      //urlArr.push(response.result);
      this.urlArr.push(response.result)
    },
    async getContent(id) {
      const { data: res } = await this.$http.get('/school/detail', { params: { id: id }})
      this.loading = false;
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.content = res.result.content
        this.addForm = res.result
        this.bannerArr = res.result.banner.split(",");
        console.log('addForm.content:', this.addForm.content);
          this.$forceUpdate();//层级太深时使用
          this.finish = true;
      }
    },
    //返回上一页
    goBack() {
      this.$router.go(-1);
    },
    //保存
    async preserveNews(){
      if(this.addForm.name === '' || this.addForm.name == null){
        return this.$message.error('请输入标题！');
      }
      // if(this.addForm.content === '' || this.addForm.content == null){
      //   return this.$message.error('请输入内容！');
      // }
        if(this.$refs.quillView.content === '' || this.$refs.quillView.content == null){
            return this.$message.error('请输入内容！');
        }
      this.btnLoading = true;
      //let newArr = this.bannerArr + this.urlArr;

      let newArr = this.bannerArr.concat(this.urlArr);
      let formData = {
        name: this.addForm.name,//标题
        address: this.addForm.address,//学校地址
        property: this.addForm.property,//办学性质
        section: this.addForm.section,//招生阶段
        scale: this.addForm.scale,//设计规模
        content: this.$refs.quillView.content,//文本编辑器
        banner: newArr.join(',')
      }
      let result;
      if(this.$route.query.id) {//编辑
        formData.id = this.$route.query.id
        //result = await this.$http.put('/school/update', formData)
        result = await this.$http({
          url: '/school/update',
          data: formData,
          method:'put',
          timeout: 120000
        })
        console.log('编辑：')
      }else {//添加
       // result = await this.$http.post('/school/add', formData)
        result = await this.$http({
          url: '/school/add',
          data: formData,
          method:'pust',
          timeout: 120000
        })
      }
      let res = result.data;
      this.btnLoading = false;
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.addDialogVisible = false
        this.$message.success(res.message)
        await this.$router.push('/school')
      }
    },
  }
}
</script>
<style scoped>
.view-header{
  margin:0 -24px;
  padding:0 24px 20px;
  border-bottom:1px solid #E9E9E9;
}
.sub-view-container{width: 100%;margin:0 auto;margin-top:24px;}
.head_editor{
  padding: 12px 0;
  background-color: #F3F3F3;
}
.head_editor .inputC{ font-size: 24px; color: #20243A; border: none; width: 80%; padding: 0 20px; }
.head_editor .inputC::-webkit-input-placeholder { color: #20243A; }
.diveditor{margin-top:16px;height: 390px;}
.view-source{display:flex;align-items:center;padding:8px 24px;background-color:#F3F3F3;color:#666666;}
.view-source .el-input{flex:1;}
.btneditor{margin-top:16px;text-align:center;}
.btneditor .el-button{width:96px;}
.info {
  margin-top: 16px;
}
</style>
<style>
.photo-list {
  box-sizing: border-box;
  padding-top: 20px;
  display: flex;
  /*justify-content: start;
  flex-wrap: wrap;*/
  flex-flow: wrap;
}
.photo-item {
  width: 245px;
  height: 164px;
  /*background-color: #41B883;*/
  margin-bottom: 20px;
  margin-right: 10px;
  position: relative;
  /*background-color: #55a532;*/
}
.photo-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}
.photo-item .edit {
  position: absolute;
}
.edit {
  float: left;
  line-height: 100%;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 20px;
  text-align: center;
  background-color: rgba(0,0,0,.5);
  justify-content: space-around;
  display: flex;
}
.edit i {
  margin-top: 30%;
  cursor: pointer;
}
.photo-item .edit {visibility:hidden;}
.photo-item:hover .edit{visibility:visible;}

</style>
<style>
.el-upload {
  width: 260px;
  height: 168px;
  position: relative;
}
.tip{
  font-size: 13px;
  position: absolute;
  float: left;
  left: 23%;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 260px;
  height: 168px;
}
.add-icon {
  position: absolute;float: left; left: 45%;top: 45px;
}
.el-upload-list--picture-card .el-upload-list__item{
  border: none;
}
</style>
